import { gql } from '@apollo/client';
import { InvoiceMessageFields } from '../fragments';
import { MailParametersTypeFields } from './fragments';

export const READ_NOTIFICATIONS = gql`
mutation markNotificationAsRead($notificationIds: [ID]) {
  markNotificationAsRead(notificationIds: $notificationIds) {
    success
  }
}
`;

export const CREATE_INVOICE_MESSAGE = gql`
  mutation CreateInvoiceMessage($invoiceId: Int!, $message: String!, $messageType: InvoiceMessageTypeEnum!, $userIdMentions: [Int]) {
    createInvoiceMessage(invoiceId: $invoiceId, message: $message, messageType: $messageType, userIdMentions: $userIdMentions) {
      createdMessage{
        ... InvoiceMessageFields
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const NEW_INVOICE_MESSAGE = gql`
  subscription NewInvoiceMessage($invoiceId: Int!) {
    newInvoiceMessage(invoiceId: $invoiceId) {
      invoiceMessage {
        ... InvoiceMessageFields
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const READ_ALL_MESSAGES = gql`
  mutation readAllMessages($invoiceId: Int!) {
    readAllMessages(invoiceId: $invoiceId) {
      invoice {
        id
        unreadMessages
      }
    }
  }
`;

export const INVOICE_STATUS_MAIL_PREVIEW = gql`
  mutation getInvoiceStatusMailPreview($emailType: String!, $invoiceId: Int!, $userId: Int!) {
    getInvoiceStatusMailPreview(emailType: $emailType, invoiceId: $invoiceId, userId: $userId) {
      success
      message
      preview
    }
  }
`;

export const SEND_INVOICE_STATUS_MAIL = gql`
  mutation sendInvoiceStatusMail($emailType: String!, $invoiceId: Int!, $userId: Int!, $contactsIds: [Int]!) {
    sendInvoiceStatusMail(emailType: $emailType, invoiceId: $invoiceId, userId: $userId, contactsIds: $contactsIds) {
      success
      message
    }
  }
`;

export const CREATE_UPDATE_MAIL = gql`
  mutation CreateUpdateMail($id: ID, $emailName: String!, $ipPoolName: String!, $asm: ID) {
    createUpdateMail(
      id: $id,
      emailName: $emailName,
      ipPoolName: $ipPoolName
      asm: $asm,
    ) {
      mail {
        id
        emailName
        asm {
          id
          groupName
        }
        ipPoolName
        mailparametersSet {
          ... MailParametersTypeFields
        }
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const CREATE_UPDATE_MAIL_PARAMETERS = gql`
  mutation CreateUpdateMailParameters(
    $id: ID,
    $mail: ID!,
    $country: ID!,
    $requestingPlatform: ID!,
    $fromEmail: ID,
    $mailTemplate: ID,
    $bcc: [ID],
    $cc: [ID],
    $to: [ID]
  ) {
    createUpdateMailParameters(
      id: $id
      mail: $mail
      country:$country
      requestingPlatform: $requestingPlatform
      fromEmail: $fromEmail
      mailTemplate: $mailTemplate
      bcc: $bcc
      cc: $cc
      to: $to
    ) {
      mailParameters {
        ... MailParametersTypeFields
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const CREATE_UPDATE_MAIL_TEMPLATE = gql`
mutation CreateUpdateMailTemplate(
  $id: ID,
  $version: String!,
  $template: String,
  $mailParameter: ID!
) {
  createUpdateMailTemplate(
    id: $id
    version: $version
    template: $template
    mailParameter: $mailParameter
  ) {
      mailParameter {
        ... MailParametersTypeFields
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const ADD_CONTROL_GROUP = gql`
  mutation addControlGroup($companyId: ID!, $mailParameterId: ID!) {
    addControlGroup(companyId: $companyId, mailParameterId: $mailParameterId) {
      mailParameter {
        ... MailParametersTypeFields
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const DELETE_CONTROL_GROUP = gql`
  mutation deleteControlGroup($companyId: ID!, $mailParameterId: ID!) {
    deleteControlGroup(companyId: $companyId, mailParameterId: $mailParameterId) {
      success
    }
  }
`;

export const ADD_CONTROL_GROUPS_BY_FILE = gql`
  mutation addControlGroupsByFile($companyFile: Upload!, $mailParameterId: ID!) {
    addControlGroupsByFile(companyFile: $companyFile, mailParameterId: $mailParameterId) {
      updatedGroups
    }
  }
`;

export const DELETE_CONTROL_GROUPS_BY_FILE = gql`
  mutation deleteControlGroupsByFile($companyFile: Upload!, $mailParameterId: ID!) {
    deleteControlGroupsByFile(companyFile: $companyFile, mailParameterId: $mailParameterId) {
      deletedGroups
    }
  }
`;
