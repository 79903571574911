/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { InvoiceMessageFields } from '../fragments';
import { FileFields } from '../fileField';
import { MailParametersTypeFields } from './fragments';

export const NOTIFICATION_LIST = gql`
  query getNotifications(
    $first: Int
    $offset: Int
    $unread: Boolean
    $globalFilter: String
    $timestamp_Lte: DateTime
    $timestamp_Gte: DateTime
  ) {
    getNotifications(
      first: $first
      offset: $offset
      unread: $unread
      globalFilter: $globalFilter
      timestamp_Lte: $timestamp_Lte
      timestamp_Gte: $timestamp_Gte
    ) {
      totalPages
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          timestamp
          level
          unread
          actorStr
          verbStr
          targetStr
          redirectionPath
        }
      }
    }
  }
`;

export const INVOICE_MESSAGES_QUERY = gql`
  query InvoiceMessage(
    $first: Int
    $after: String
    $invoiceId: Int!
    $messageType: String
  ) {
    invoiceMessages(
      first: $first
      after: $after
      invoiceId: $invoiceId
      messageType: $messageType
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          ...InvoiceMessageFields
        }
      }
    }
  }
  ${InvoiceMessageFields}
`;

export const USER_MENTIONS_QUERY = gql`
  query userMentions($invoiceId: Int!, $messageType: String) {
    userDisplayForMentionInvoice(
      invoiceId: $invoiceId
      messageType: $messageType
    ) {
      id
      display
    }
  }
`;

export const MAIL_LOG = gql`
  query mailLog(
    $first: Int
    $offset: Int
    $status_Status: String
    $invoices_Id_In: [String]
    $mailParameter_Mail_EmailName: String
  ) {
    mailLog(
      first: $first
      offset: $offset
      status_Status: $status_Status
      invoices_Id_In: $invoices_Id_In
      mailParameter_Mail_EmailName: $mailParameter_Mail_EmailName
    ) {
      pageInfo {
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        node {
          id
          to
          cc
          bcc
          fileSet {
            id
            name
            attachment {
              ...FileFields
            }
          }
          dynamicTemplateData
          status {
            id
            status
          }
          mailParameter {
            id
            mail {
              id
              emailName
            }
          }
          masterEntity {
            id
            rut
            name
            displayNationalIdentifier
            riskBlacklist {
              id
            }
          }
          invoices {
            edges {
              node {
                id
                folio
                dteType {
                  code
                }
              }
            }
            flatInvoices @client {
              id
              folio
              dteType {
                code
              }
            }
          }
        }
      }
    }
  }
  ${FileFields}
`;

export const SEND_MAILS = gql`
  mutation sendMails($mailLogIds: [ID]!) {
    sendMails(mailLogIds: $mailLogIds) {
      success
    }
  }
`;

export const DISCARD_CONTACTS = gql`
  mutation discardContacts($mailLogIds: [ID]!) {
    discardContacts(mailLogIds: $mailLogIds) {
      success
      numberOfContactsDiscarded
    }
  }
`;

export const MAILS = gql`
  query mails(
    $first: Int
    $after: String
    $orderBy: String
    $emailName_Icontains: String
  ) {
    mails(
      first: $first
      after: $after
      orderBy: $orderBy
      emailName_Icontains: $emailName_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages
      edges {
        cursor
        node {
          id
          emailName
          label @client
          createdAt
        }
      }
    }
  }
`;

export const MAILS_WITH_PARAMETERS = gql`
  query mails(
    $first: Int
    $after: String
    $orderBy: String
    $emailName_Icontains: String
    $globalFilter: String
  ) {
    mails(
      first: $first
      after: $after
      orderBy: $orderBy
      emailName_Icontains: $emailName_Icontains
      globalFilter: $globalFilter
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalPages  
      edges {
        cursor
        node {
          id
          graphqlId @client
          emailName
          asm {
            id
            groupName
          }
          ipPoolName
          mailparametersSet {
            ... MailParametersTypeFields
          }
        }
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const SINGLE_MAIL_WITH_PARAMETERS = gql`
  query mail(
    $mailId: ID!
  ) {
    mail(
      mailId: $mailId
    ) {
      id
      emailName
      asm {
        id
        groupName
      }
      ipPoolName
      mailparametersSet {
        ... MailParametersTypeFields
      }
    }
  }
  ${MailParametersTypeFields}
`;

export const WHATSAPP_LOGS = gql`
  query whatsappLogs(
    $first: Int
    $offset: Int
    $after: String
    $orderBy: String
    $conversationHash: String!
    $twilioSenderId: Int!
  ) {
    whatsappLogs(
      first: $first
      offset: $offset
      after: $after
      orderBy: $orderBy
      conversationHash: $conversationHash
      twilioSenderId: $twilioSenderId
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          body
          inbox
          From
          createdAt
          extraData
          preOffer {
            id
            invoice {
              id
              folio
            }
          }
          numMedia
          flatStatus @client
          whatsapplogfileSet {
            id
            file {
              name
              url
            }
            contentType
          }
          whatsapplogeventdetailSet {
            id
            whatsappDateCreated
            whatsappLogEvent {
              id
              status
            }
          }
        }
      }
    }
  }
`;

export const EMAIL_FROM_MAIL_LOG = gql`
  query mailLogEmail($encryptedMailLogId: String!) {
    mailLogEmail(encryptedMailLogId: $encryptedMailLogId)
  }
`;

export const GET_ASM = gql`
  query getAsm {
    getAsm {
      id
      groupId
      groupName
      description
    }
  }
`;
