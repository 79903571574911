/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { UserIdentification } from '../user/fragments';

export const MailLogTypeFields = gql`
  fragment MailLogTypeFields on MailLogType {
    id
    to
    fromEmail {
      ... UserIdentification
    }
    sendGridData {
      id
      events {
        id
        status
        eventTime
      }
    }
  }
  ${UserIdentification}
`;

export const MailParametersTypeFields = gql`
  fragment MailParametersTypeFields on MailParametersType {
    id
    country {
      id
      name
    }
    requestingPlatform {
      id
      code
    }
    mailTemplate {
      id
      version
      template
    }
    fromEmail {
      id
      email
    }
    to {
      id
      email
    }
    bcc {
      id
      email
    }
    cc {
      id
      email
    }
  }
`;
